<template>
  <div class="root_layout">
    <van-loading type="spinner" size="30px" text-size="18px">{{showText}}</van-loading>
  </div>
</template>

<script>
import * as common from '../../api/common'

export default {
  name: 'codeObtain',
  data () {
    return {
      showText: '加载中...'
    }
  },
  mounted () {
    let code
    if (common.getUserAgent() === 'wx') {
      code = this.getUrlParam('code')
    } else if (common.getUserAgent() === 'zfb') {
      code = this.$route.query.auth_code
    }
    if (!code) {
      this.skipError('获取 code 失败')
      return
    }

    const targetFullUrl = this.$route.query.url
    console.log('targetFullUrl', targetFullUrl)
    if (!targetFullUrl) {
      this.skipError('目标 url 不存在')
      return
    }

    let targetBaseUrl
    const curBaseUrl = common.getUrlObjBase(window.location)
    console.log('curBaseUrl', curBaseUrl)
    try {
      targetBaseUrl = common.getUrlObjBase(new URL(targetFullUrl))
      console.log('targetBaseUrl', targetBaseUrl)
    } catch (e) {
      this.skipError('解析 url 失败')
      return
    }

    if (targetBaseUrl !== curBaseUrl) {
      this.skipError('目标 url 域名路径异常')
      return
    }

    let newTargetFullUrl
    if (common.getUserAgent() === 'wx') {
      newTargetFullUrl = this.addParamVal(targetFullUrl, 'code', code)
      window.location.replace(newTargetFullUrl)
    } else if (common.getUserAgent() === 'zfb') {
      newTargetFullUrl = this.addParamVal(targetFullUrl, 'auth_code', code)
      this.ZFBReady(function () {
        // eslint-disable-next-line no-undef
        AlipayJSBridge.call('pushWindow', {
          url: newTargetFullUrl,
          param: {
            closeCurrentWindow: true
          }
        })
      })
    }
  },
  methods: {
    getUrlParam (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') // 构造一个含有目标参数的正则表达式对象
      const r = window.location.search.substr(1).match(reg) // 匹配目标参数
      if (r != null) {
        return decodeURIComponent(r[2])
      }
      return null // 返回参数值
    },
    addParamVal (url, paramName, paramValue) {
      const re = new RegExp(`(${paramName}=)([^&]*)`, 'gi')
      let newUrl
      if (url.match(re)) {
        // newUrl = url.replace(re, "$1" + paramName + "=" + paramValue + "$2");
        newUrl = url.replace(re, `${paramName}=${encodeURIComponent(paramValue)}`)
      } else {
        const separator = url.indexOf('?') !== -1 ? '&' : '?'
        newUrl = url + separator + paramName + '=' + encodeURIComponent(paramValue)
      }
      return newUrl
    },
    skipError (msg) {
      this.$dialog.alert({
        title: '提示',
        message: msg || '跳转参数出错'
      }).then(() => {
        this.showText = '关闭页面中...'
        if (common.getUserAgent() === 'wx') {
          this.WX_Bridge_ready(() => {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.call('closeWindow')
          })
        } else if (common.getUserAgent() === 'zfb') {
          this.ZFBReady(() => {
            // ap.popWindow()
            // eslint-disable-next-line no-undef
            AlipayJSBridge.call('popWindow', {
              data: {
                from: location.href,
                info: Date.now()
              }
            })
          })
        }
      })
    },
    WX_Bridge_ready (cb) {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', cb(), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', cb())
          document.attachEvent('onWeixinJSBridgeReady', cb())
        }
      } else {
        cb()
      }
    },
    ZFBReady (callback) {
      if (window.AlipayJSBridge) {
        callback && callback()
      } else {
        if (document.addEventListener) {
          document.addEventListener('AlipayJSBridgeReady', callback, false)
        } else if (document.attachEvent) {
          document.attachEvent('AlipayJSBridgeReady', callback)
        }
      }
    }
  }
}
</script>

<style scoped>
.root_layout{
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
